import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Aidan Collins', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Developer Portfolio', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: '',
  subtitle: '',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: '',
  paragraphTwo: '',
  paragraphThree: '',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  /*{
    id: nanoid(),
    img: 'dollar.png',
    title: 'FIRE Calculator',
    info:
      'This is a calculator GUI built with Python and tkinter. It will calculate your retirement date based on your current finances. FIRE stands for "Financial Independence Retire Early".',
    info2: '',
    url: 'https://github.com/aidancollins/FIRE_Calculator',
  },
  {
    id: nanoid(),
    img: 'scattergories.png',
    title: 'Scattergories Dice Roller',
    info:
      'This Dice Roller GUI is built with Java and swing. It replaces the dice in the Scattergories board game to generate random legal letters for gameplay. It is essential if the dice goes missing!',
    info2: '',
    url: 'https://github.com/aidancollins/scattergoriesGUI',
  },
  {
    id: nanoid(),
    img: 'student.png',
    title: 'Student Database',
    info:
      'This relational database built with SQL was made for a class assignment during CSC 370 at the University of Victoria. It supports class registration and course pre-requisites along with teacher scheduling conflicts.',
    info2: '',
    url: 'https://github.com/aidancollins/studentDatabase',
  },
  {
    id: nanoid(),
    img: 'camera.png',
    title: 'Photography Website',
    info:
      'I built this site to showcase my landscape photography portfolio. Future plans for the site include adding e-commerce features along with secure payment options for purchasing photo prints.',
    info2: '',
    url: 'https://github.com/aidancollins/Photography_Site',
  },*/
  {
    id: nanoid(),
    img: '',
    title: '"Video for Business" online workshop',
    info:
      'When the COVID-19 pandemic began, we were faced with postponed contracts, cancelled events, and plenty of free time. No longer being able to offer our in-person group workshops, I worked closely with our team to take the entire education process online and reach a larger audience.',
    info2: '',
    url: 'https://rollfocus.thinkific.com',
  },
  {
    id: nanoid(),
    img: '',
    title: '"Video for Business: Take your marketing to the next level with professional video" eBook',
    info:
      'I am the author and photographer for this eBook, which is meant as an accompaniment to the online workshop, and provide a free resource to our clients to help them maximize their investment in video production.',
    info2: '',
    url: 'https://rollfocus.com/ebook',
  },
  {
    id: nanoid(),
    img: '',
    title: 'Sierra Club BC video',
    info:
      'This is a video project that I am proud of, and played a major role in creating. My contribution to this video includes: Planning and location scouting, filming, editing.',
    info2: '',
    url: 'https://rollfocus.com/blog/sierraclubbc',
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'aidancollins97@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/aidan-collins-13821811b',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/aidancollins',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
